import React, { useState, useRef, useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { Container, Typography, Grid, makeStyles } from '@material-ui/core';

import { Hero } from '../../components/General/Hero';
import { WaveUpSVG } from '../../components/WaveSVGs/WaveUpSVG';
import { SEO } from '../../components/SEO';
import { FAQ } from '../../components/General/FAQ';
import { CtaCard } from '../../components/General/CtaCard';
import FaqSearchContext from '../../context/FaqSearchContext';
import FormModalContext from '../../context/FormModalContext';

// import { Form } from '../../components/ContactForm/Form';

const Form = loadable(() => import('../../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	faqSection: {
		marginTop: '-17em',
		[theme.breakpoints.down('lg')]: {
			marginTop: '-13em',
		},
		// [theme.breakpoints.down('md')]: {
		// 	marginTop: '-13em',
		// },
		[theme.breakpoints.down('sm')]: {
			marginTop: '-8em',
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: '-4em',
		},
	},
}));

const PaymentsFAQ = ({ data, location }) => {
	const faqData = data.fs.edges[0].node.faq;
	const [expanded, setExpanded] = useState(0);
	const faqRef = useRef(null);
	const { formModalOpen } = useContext(FormModalContext);

	const classes = useStyles();

	const { setDefault, filteredFaq } = useContext(FaqSearchContext);

	const {
		faqPageHero,
		metaDescription,
		metaTitle,
		ctaBackground,
		ctaHeader,
		ctaSubheader,
		ctaText,
		faq,
		faqDisclaimer,
	} = faqData;

	useEffect(() => {
		setDefault(faq);
	}, []);
	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />

			<Hero hero={faqPageHero} faq={faq} />
			<Container
				className={classes.faqSection}
				style={{ marginBottom: '15rem' }}>
				<Grid container justifyContent='center' style={{ padding: '2rem 0' }}>
					<Typography
						variant='h2'
						color='primary'
						style={{ textAlign: 'center' }}>
						FAQ
					</Typography>
				</Grid>

				{!!filteredFaq.length ? (
					filteredFaq.map((faq, index) => (
						<FAQ
							key={index}
							faq={faq}
							index={index}
							expanded={expanded}
							setExpanded={setExpanded}
							faqRef={faqRef}
						/>
					))
				) : (
					<Typography variant='h4' color='primary'>
						No Match
					</Typography>
				)}
				<Typography variant='body1' style={{ lineHeight: '28px' }}>
					{faqDisclaimer}
				</Typography>
				<CtaCard
					background={ctaBackground}
					header={ctaHeader}
					subheader={ctaSubheader}
					ctaText={ctaText}
				/>
				{formModalOpen ? (
					<Form
						formId='3646'
						privacy
						noForm
						location={location}
						pardotUrl='https://go.getservman.com/l/1042281/2023-10-12/65r4qv'
					/>
				) : null}
			</Container>
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
		</>
	);
};

export const query = graphql`
	query FSFAQPageQuery {
		fs: allSanityFinancialServices {
			edges {
				node {
					faq: fsFaqPage {
						metaTitle
						metaDescription
						faqPageHero {
							backgroundImage {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
							_rawContent
						}
						faq {
							question
							_rawAnswer
						}
						faqDisclaimer
						ctaHeader
						ctaSubheader
						ctaText
						ctaBackground {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
				}
			}
		}
	}
`;

export default PaymentsFAQ;
